import { CurrencyMaskInputMode } from 'ngx-currency';

export const dateFormat = {
  YYYYMMDD: 'YYYY-MM-DD',
  MMDDYYYY: 'MM/DD/yyyy',
  MMMMDDYYYY: 'MMMM DD, yyyy',
  MMMMYYYY: 'MMMM, yyyy',
  MMMM_YYYY: 'MMMM yyyy',
};

export class BenefitTypes {
  type: string;
  icon: string;
}

export const ModalCssClass = {
  XLargeModal: 'modal-xl modal-dialog modal-dialog-centered modal-dialog-scrollable messageModal modal-dialog-centered',
  LargeModal: 'modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable messageModal modal-dialog-centered',
  SmallModal: 'modal-sm modal-dialog modal-dialog-centered modal-dialog-scrollable messageModal modal-dialog-centered',
  MediumModal: 'modal-md modal-dialog modal-dialog-centered modal-dialog-scrollable messageModal modal-dialog-centered',
  MessageModal:
    'messageModal modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-md-down modal-md',
};

export const ShoppingCartMode = {
  Create: 'create',
  Edit: 'edit',
};
export const ShoppingCartType = {
  ShoppingCart: 'shoppingCart',
  TempShoppingCart: 'tempShoppingCart',
};

export const ParkingProductCode = {
  PCCCCode: 'PCCC',
  VoucherCode: 'CHECK',
  ReimbursementCode: 'CR',
  DirectPayCode: 'DP',
  AutoSweepCode: 'AUTOSWEEP',
  PCCCFlexCode: 'PCCCFLEX',
  EmployerParking: 'EMPPRK',
  NYCPCCCCode: 'NYCPCCC',
  CUNYPCCC: 'CUNYPCCC',
  PSC: 'PSC',
  CBPCCC: 'CBPCCC',
  CBPCCCAS: 'CBPCCCAS',
  EBPCCC: 'EBPCCC',
};

export const Status = {
  Pending: 'pending',
  PendingApproval: 'pending approval',
  Approved: 'approved',
  ApprovedCompany: 'Approved-Company',
  PartiallyApproved: 'partially approved',
  Declined: 'declined',
  Denied: 'denied',
  DeniedByCompany: 'denied-company',
  Received: 'received',
  Fulfilled: 'fulfilled',
  sentFullfillment: 'sent in for fullfillment',
  Active: 'Active',
  Closed: 'Closed',
  New: 'new',
  Purchase: 'purchase   ',
  purchase: 'purchase',
  InReview: 'In Review',
};
export const PageName = {
  Dashboard: 'DASHBOARD',
  Card: 'CARD',
  Claims: 'CLAIMS',
  Parking: 'PARKING',
};

export const PageHeadings = {
  DBBED: 'DBBED',
  DBPAR: 'DBPAR',
  DBSHA: 'DBSHA',
  DBAVSP: 'DBAVSP',
  CAMYC: 'CAMYC',
  CAMYCCONT: 'CAMYCCONT',
  CACAAC: 'CACAAC',
  CASPRU: 'CASPRU',
  CASPRUCONT: 'CASPRUCONT',
  CLMYCL: 'CLMYCL',
  CLMYCLCONT: 'CLMYCLCONT',
  CLBATR: 'CLBATR',
  CLBATRCONT: 'CLBATRCONT',
  CLCHIS: 'CLCHIS',
  PARYPR: 'PARYPR',
  PARMAPR: 'PARMAPR',
  PARYPRCONT: 'PARYPRCONT',
  DBREA: 'DBREA',
  DBSUB: 'DBSUB',
  DBCLM: 'DBCLM',
};

export const PageSubHeading = {
  SUBHDR: 'SUBHDR',
  SUBHDRCONT: 'SUBHDRCONT',
  TOTALCARDBAL: 'TOTALCARDBAL',
  SUBHDRFLEX: 'SUBHDRFLEX',
  SUBHDRFLEXCONT: 'SUBHDRFLEXCONT',
  EMPCONTRI: 'EMPCONTRI',
  SUBHDRMAIL: 'SUBHDRMAIL',
  SUBHDRACTIVATE: 'SUBHDRACTIVATE',
  REIMBURSEMETHOD: 'REIMBURSEMETHOD',
  REIMBURSEBALANCE: 'REIMBURSEBALANCE',
  USEBALANCE: 'USEBALANCE',
  REIMBURSEPARKING: 'REIMBURSEPARKING',
  FUNDSAVAIL: 'FUNDSAVAIL',
  TOTALUSAGE: 'TOTALUSAGE',
  NUMBERSMEAN: 'NUMBERSMEAN',
  SUBHDRREF: 'SUBHDRREF',
  SUBHDRVAL: 'SUBHDRVAL',
  SUBHDRTR: 'SUBHDRTR',
};

export const TransportationCategory = {
  Transit: 'Transit',
  Parking: 'Parking',
  Micromobility: 'Micromobility',
  Biking: 'Biking',
  BikeMobility: 'BikeMobility',
  CombinedCaps: 'CombinedCaps',
  RideMBB: 'RideMBB',
};

export const Messages = {
  idleTitle: "You've been logged out",
  idleMessage:
    'To protect your information, we’ve logged you out for inactivity. Please log back in to access your account again.',
  emptyShoppingCart: 'Shopping cart is empty. Please add new benefit.',
  reportLostTitle: 'Report this card as lost',
  neverReceived: 'Never Received card',
  termsAndConditions: 'Terms & Conditions',
  termsAndConditionsMessage: 'Please accept the terms of services.',
  taFeeMessage: 'Please accept the fee(s).',
  DeliveryAddressSuccess: 'Delivery Address has been updated successfully.',
  officeAddressSuccess: 'Office Address has been updated successfully.',
  NoProductFound: 'There is no product found for ',
  termsContent:
    'I agree that the above product(s) order is correct, and that I certify that all product(s) will be used by me only for the purposes of commuting to and from work at the Employer. As applicable, I authorize my Employer to deduct the amount of my order that I am responsible for paying as noted above from my paycheck up to the applicable monthly IRS pre-tax limit. I am also responsible for any remainder on a post-tax basis.',
  updateContactInfo: 'Contact details has been updated successfully',
  updateDeliveryAddress: 'Delivery address has been updated successfully',
  updateOfficeAddress: 'Office address has been updated successfully',
  updateCreditInfo: 'Credit Card details has been updated successfully',
  updateACHDetails: 'Account details has been updated successfully',
  updateError: 'Some error occurred, Please Try again!',
  failedUpdate: 'Failed to update details, Please Try again!',
  activateCard: 'Your card will be activated shortly!',
  noTransactions: 'No transactions found for this card!',
  updatePassword: 'Password has been updated successfully!',
  noPasswordMatch: 'Passwords do not match',
  wrongOldPassword: 'The old Password is invalid',
  alert: 'Alert',
  creditCardEdit:
    '<h2 class="center-text">Are you sure you don\'t want to enter your credit card now?</h2> <p>If you choose Continue, you will have until ##lastDateToOrder## to update your profile with a valid credit card, or your order will not be processed.</p>',
  apiError: 'Oops! Something went wrong. Please try again, or contact Customer Service if this problem continues.',
  scReplacementRequest:
    'Your request has been submitted. Please allow 3-5 business days for your new Smart Card to arrive.',
  failedRequest: 'Request Failed, Please try again later!',
  claimExist: 'Claim already exists!',
  claimCreationSuccess: 'Your claim will be processed within 5 days after the claim submission status is complete.',
  noUpdateACH: 'Your ACH information could not be saved. Please try again or contact Customer Service.',
  selectValidDate: 'Please select valid Date',
  requiredField: 'Please enter Required fields!',
  noClaimSubmission: 'Error occurred, Not able to submit claim.Please try again later!',
  mobileWalletSetUP: 'Your card is now enabled for mobile wallet access!',
  waitListMessage: 'You are already on the waitlist for this parking lot.',
  incorrectCode: 'Incorrect verification code!',
  resendCode: 'A new code has been sent to your mobile!',
  unsubscribeTexting: 'You have successfully unsubscribed from texting!',
  WaitlistTitle: 'Information',
  WaitlistConfirmationMessage: 'Are you sure you want to cancel this request?',
  WaitlistCancelbtnText: 'No Thanks',
  empIDOrFirstName:
    'Please Enter either First name, Last name, Home Zip Code <span class= font-weight-bold> OR </span> Employee ID!!',
  reEnterCardInfo: 'To update information, please re-enter the card number.',
  noChangeInForm: 'No Change has been made!',
  deleteCreditCard: 'Credit Card details has been deleted!',
  invalidCreditCard: 'Invalid Credit Card Number!',
  uploadReceiptError: 'Please upload a receipt and click "Update."',
  uploadReceiptSuccess: 'Receipt has been Uploaded successfully!!',
  maxTransferLimit: 'Exceeds max card balance. You may load up to $2,000',
  trnPreTaxError: 'You can not load amount greater than available credit in Transit Pre-tax.',
  trnPostTaxError: 'You can not load amount greater than available credit in Transit Post-tax.',
  prkPreTaxError: 'You can not load amount greater than available credit in Parking Pre-tax.',
  prkPostTaxError: 'You can not load amount greater than available credit in Parking Post-tax.',
  mbbPreTaxError: 'You can not load amount greater than available credit in MBB Pre-tax.',
  mbbPostTaxError: 'You can not load amount greater than available credit in MBB Post-tax.',
  noChangeFound: 'You have not changed the banking information',
  MemberCIPError:
    'Unfortunately, we were unable to verify your personal information and cannot complete your requested order using post-tax funds from the credit card provided. Our Compliance Department will reach out within one business day to verify additional information. To skip this verification process, please order up to the pre-tax limit allowed.',
};

export const FieldTypes = {
  Text: 'TXT',
  Amount: 'AMT',
  TermsCondition: 'AGREE',
  CustomField: 'customfield',
  RegEx: 'REGEX',
  Input: 'INPUT',
  Selection: 'SELECTION',
};

export const ButtonText = {
  Close: 'Close',
  BackToLogin: 'Back to Login',
  Continue: 'Continue',
};

export const MessageType = {
  Success: 'Success',
  Error: 'Error',
  Warning: 'Warning',
};

export const ActionTypeCode = {
  SCREPLACE: 'SCREPLACE',
  PASSNEVERRECEIVED: 'PASSNEVERRECEIVED',
  CLAIMSTATUS: 'CLAIMSTATUS',
  FILECLAIM: 'FILECLAIM',
  NEEDHELP: 'NEEDHELP',
  PCCCDetail: 'PCCCDetail',
  PCCCLOSTSTOLEN: 'PCCCLOSTSTOLEN',
  PCCCNEVERRECEIVED: 'PCCCNEVERRECEIVED',
  PCCCACTIVATE: 'PCCCACTIVATE',
  UPLOADRECEIPT: 'UPLOADRECEIPT',
};
export const ebCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

export const ParkingProducts = {
  CalTrain: 'CalTrain Monthly Parking Permit',
  MonthlyDirectPay: 'Monthly Direct Pay',
  DirectPay: 'Direct Pay',
  WMataParking: 'WMATA Parking',
  WMataSmartCard: 'WMATA SmarTrip Card for Parking',
  UnaffiliatedDirectPay: 'Unaffiliated Monthly Direct Pay',
  EmployerParking: 'Employer Parking',
  ParkingCashout: 'Parking Cash-Out',
};

export const YesNo = {
  Yes: 'Yes',
  No: 'No',
};

export const PageTitle = {
  ClaimType: 'Claims Type | Edenred Employee Benefits',
  ConfigureBenefits: 'Configure Benefits | Edenred Employee Benefits',
  Dashboard: 'Dashboard | Edenred Employee Benefits',
  ElectBenefits: 'Elect Benefits | Edenred Employee Benefits',
  Profile: 'Profile | Edenred Employee Benefits',
  SubmitClaim: 'Submit Claim | Edenred Employee Benefits',
  ViewClaim: 'View Claim | Edenred Employee Benefits',
  ClaimHome: 'Claims | Edenred Employee Benefits',
  CardHome: 'Card | Edenred Employee Benefits',
  BenefitType: 'Benefit Type | Edenred Employee Benefits',
  BenefitDetails: 'Benefit Details | Edenred Employee Benefits',
  ReviewOrder: 'Review order | Edenred Employee Benefits',
  OrderConfirmation: 'Order Confirmation | Edenred Employee Benefits',
  SmartcardReplacement: 'Card Replacement | Edenred Employee Benefits',
  LostPass: 'Lost Pass | Edenred Employee Benefits',
  Login: 'Login | Edenred Employee Benefits',
  Registration: 'Registration | Edenred Employee Benefits',
  AppName: 'Employee Benefits',
  Logout: 'Logout | Edenred Employee Benefits',
};

export const CreditDebitType = {
  Credit: 'Credit',
  Debit: 'Debit',
};

export class purseTypeArray {
  Type: string;
  amount: number;
}

export const purseTypes = {
  TransitPreTax: 'Transit Pre-Tax',
  TransitPostTax: 'Transit Post-Tax',
  ParkingPreTax: 'Parking Pre-Tax',
  ParkingPostTax: 'Parking Post-Tax',
  MBBPreTax: 'MBB Pre-Tax',
  MBBPostTax: 'MBB Post-Tax',
};

export const subsidyTooltip = {
  subsidyPercent: 'The subsidy percent your employer contributes to your account each month.',
  subsidyAmount: 'The amount your employer contributes to your account each month.',
  harvardSubsidy: 'The subsidy percent Harvard University contributes towards your monthly order.',
};
