<div class="eb-cards row mx-0 pl-0 pr-0">
  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 pl-0 pr-0">
    <div class="border-0">
      <div class="mx-0 p-2 pb-lg-3 row help-menu-header bg-header-color">
        <div class="col-9 col-xl-9">
          <h5 class="modal-title pt-2" id="messageModalLabel">
            <img src="assets/images/coloredHelpIcon.svg" alt="icon" />&nbsp;<span class="font-weight-600">
              Help Menu</span
            >
          </h5>
        </div>
        <div class="col-xl-3 col-3 text-right pr-lg-0 pr-0">
          <button type="button" class="bg-transparent border-0" aria-label="Close" (click)="modalRef.close('')">
            <img src="assets/images/close.png" class="close-focus" alt="icon" />
          </button>
        </div>
      </div>
      <div class="mt-2 pb-0">
        <ul class="pl-0 menu-list">
          <li class="menu-items px-4 mx-0 mt-lg-4 mt-2 cursor-pointer" (click)="redirectToFaqs()">
            <img src="assets/images/menu_book.png" alt="icon" /><span class="ml-lg-3 ml-2">Guides and FAQ’s</span>
          </li>
          <li class="menu-items px-4 mx-0 mt-lg-3 mt-2" style="display: none">
            <img src="assets/images/feedback.png" alt="icon" /><span class="ml-lg-3 ml-2">Report an Issue</span>
          </li>
          <li class="menu-items px-4 mx-0 mt-lg-3 mt-2" style="display: none">
            <a class="text-decoration-underline text-blue">COVID Policies</a>
          </li>
        </ul>
        <div class="font-16 menu-items pb-lg-3 pt-lg-2 road-assistance pt-2 pb-2">
          <div class="font-weight-bold"><i class="fcr fcr-roadside mr-1 font-weight-bold"></i>Need assistance now?</div>
          <div class="d-inline-flex" *ngIf="brandingInfo?.supportPhone">
            <div class="call-number mt-lg-2 pl-1">
              <i class="fc fc-phone mr-2 text-muted"></i
              ><span class="text-decoration-underline font-14">{{ brandingInfo?.supportPhone }}</span>
            </div>
            <div class="call-number mt-lg-2 pl-lg-4">
              <span class="font-14">M-F 8am-8pm EST</span>
            </div>
          </div>

          <div class="call-number mt-lg-2 pl-1" *ngIf="brandingInfo?.supportEmail">
            <i class="fc fc-message mr-2 text-muted"></i
            ><span class="text-decoration-underline font-14">{{ brandingInfo?.supportEmail }}</span>
          </div>
        </div>
        <div
          class="font-16 menu-items pb-lg-3 pt-lg-2 road-assistance pt-2 pb-2"
          *ngIf="company?.tpaCompanyId === 'B2346'">
          Select 'Well Being and Other Programs' from the Main Menu and then 'Commuter Services' to be connected with a
          representative that can assist.
        </div>
      </div>
    </div>
  </div>
</div>
