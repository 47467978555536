import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubsidyDTO } from '../models/subsidy';
import { AppConfigService } from './shared-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class SubsidyService {
  apiName = 'subsidyService';
  subsidyData$!: Observable<SubsidyDTO[]>;
  constructor(private http: HttpClient) {}

  getSubsidyData(idMember: string): Observable<SubsidyDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Subsidy/{idMember}';
    url = url.replace('{idMember}', idMember);
    return this.http.get<SubsidyDTO[]>(url);
  }
}
