<div class="eb-cards row mx-0 pl-0 pr-0" [ngClass]="{ 'message-scroll': isHTML }">
  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 pl-0 pr-0">
    <div class="border-0">
      <div class="help-menu-header mx-0 p-2 row">
        <div class="col-9 col-xl-9 pb-2 pt-2">
          <h3 id="messageModalLabel" class="modal-title font-weight-600">
            {{ title }}
          </h3>
        </div>
        <div class="col-3 col-xl-3 pr-0 text-right">
          <button type="button" aria-label="Close" class="bg-transparent border-0">
            <img src="assets/images/close.png" class="close-focus" alt="icon" (click)="modalRef.close('')" />
          </button>
        </div>
      </div>
      <div class="modal-body" *ngIf="!isHTML">
        <div class="row mt-3">
          <p class="font-14 font-weight-normal mb-0">{{ message }}</p>
        </div>
      </div>
      <div class="modal-body contentMessageModal m-4 custom-scroll-table-scheduled" *ngIf="isHTML">
        <div [innerHTML]="message | safeHTML"></div>
      </div>
      <div class="modal-footer noteMessageModal" *ngIf="footerNote">
        <p class="p-0">{{ footerNote }}</p>
      </div>
      <div class="noteMessageModal col-12">
        <button
          *ngIf="showCancelButton"
          id="linkBack"
          name="linkBack"
          class="btn btn-secondary float-right mb-2 btn-custom ms-3 pe-4 ps-4 ml-3 text-black w-auto font-12"
          (click)="raiseCancelEvent()">
          {{ cancelText }}
        </button>
        <button
          *ngIf="showActionButton"
          id="btn"
          class="btn btn btn-custom btn-primary float-right ms-lg-3 pe-lg-4 ps-lg-4 text-black w-auto"
          name="btn"
          (click)="raiseEvent()">
          {{ btnText }}
        </button>
      </div>
    </div>
  </div>
</div>
