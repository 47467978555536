import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AccountInformation,
  CompanyDTO,
  CompanyFeesDTO,
  CompanyLogoDTO,
  CompanySettingDTO,
  CustomizedBrandingInfo,
} from '../models/company';
import { AppConfigService } from './shared-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  apiName = 'companyService';
  companySettings$!: Observable<CompanySettingDTO>;
  constructor(private http: HttpClient) {}

  get isTrnPrkPCCCAllow(): boolean {
    var isAllow = true;
    this.companySettings$.subscribe(res => {
      if (res) {
        isAllow = !(res.isTRNpccAllow === false && res.isPrkpccAllow === false);
      }
    });
    return isAllow;
  }

  getCompanySettings(companyId: number): Observable<CompanySettingDTO> {
    const params = new HttpParams().set('companyId', companyId);
    let url = AppConfigService.apiURL(this.apiName) + '/Company/Settings';
    url = url.replace('{companyId}', companyId.toString());
    return this.http.get<CompanySettingDTO>(url, { params });
  }
  getCompany(): Observable<CompanyDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/Company';
    return this.http.get<CompanyDTO[]>(url);
  }
  getCompanyFee(companyId: number): Observable<CompanyFeesDTO[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/company/companyfee';
    if (companyId) {
      url = url + '?companyId=' + companyId;
    }
    return this.http.get<CompanyFeesDTO[]>(url);
  }
  getCompanyAccountInformation(companyId: number): Observable<AccountInformation[]> {
    let url = AppConfigService.apiURL(this.apiName) + '/company/{companyId}/accountinfo';
    url = url.replace('{companyId}', companyId.toString());
    return this.http.get<AccountInformation[]>(url);
  }
  getBrandingInfo(companyId: number): Observable<CustomizedBrandingInfo> {
    let url = AppConfigService.apiURL(this.apiName) + '/company/{companyId}/brandinginfo';
    url = url.replace('{companyId}', companyId.toString());
    return this.http.get<CustomizedBrandingInfo>(url);
  }
  getTPACompanyLogo(companyId: number, idTPA: number): Observable<CompanyLogoDTO> {
    let url =
      AppConfigService.apiURL(this.apiName) + '/company/GetTPACompanyLogo?companyId=' + companyId + '&idTpa=' + idTPA;
    return this.http.get<CompanyLogoDTO>(url);
  }
}
