import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import { OptOutMonths } from './models/app';
export class DateUtils {
  static getFirstDateOfMonth(): number {
    return Number(moment().startOf('month').format('MMDDYYYY'));
  }
  static getLastDateOfMonth(): number {
    return Number(moment().endOf('month').format('MMDDYYYY'));
  }
  static getFormattedDate(date: Date, format: string) {
    return moment(date).format(format);
  }

  // for previous day (24 hours)
  static getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  // For dynamic days
  static getPreviousDate(day: number) {
    let date = new Date();
    const previousDay = new Date(date.getTime());
    previousDay.setDate(date.getDate() - day);
    return previousDay;
  }

  // for dynamic months
  static getPreviousMonth(month: number) {
    let date = new Date();
    const previousMonth = new Date(date.getTime());
    previousMonth.setMonth(date.getMonth() - month);
    return previousMonth;
  }

  // for dynamic years
  static getPreviousYear(year: number) {
    let date = new Date();
    const previousYear = new Date(date.getTime());
    previousYear.setFullYear(date.getFullYear() - year);
    return previousYear;
  }
  static getOptoutMonths(benefitMonth: string): OptOutMonths[] {
    let date = new Date(benefitMonth);
    let months: Array<any> = [];
    for (let index = 0; index < 12; index++) {
      const futureMonth = new Date(date.setMonth(date.getMonth() + index));
      months.push(this.getOptoutMonthFromDate(futureMonth));
      date = new Date(benefitMonth);
    }
    return months;
  }
  static getOptoutMonthFromDate(date: any): OptOutMonths {
    const month: any = {};
    month.monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date) + ' ' + date.getFullYear();
    month.monthNumber = date.getMonth() + 1;
    month.month = new Date(date);
    return month;
  }
  static ToUnix(date: Date): number {
    const myutcdate = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
    const unixDate = moment.utc(myutcdate).unix();

    return unixDate;
  }
  static FromUnix(dateunix: number): Date {
    const dateLocal = new Date(dateunix * 1000);
    const newDateString = momenttz.tz(dateLocal, 'UTC').format('YYYY/MM/DD HH:mm:ss').toString();
    const newDate = new Date(newDateString);

    return newDate;
  }
  static getYearsList() {
    const yearRange = [];
    const year = new Date().getFullYear();
    yearRange.push(year);
    for (let i = 1; i < 10; i++) {
      yearRange.push(year + i);
    }
    return yearRange;
  }
  static getMonthsList() {
    const monthList = [];
    for (let i = 1; i <= 12; i++) {
      monthList.push(i);
    }
    return monthList;
  }
  static getExpirationMonthsList(year: number) {
    const monthList = [];
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    if (currentYear != year) {
      for (let i = 1; i <= 12; i++) {
        monthList.push(i);
      }
    } else {
      for (let i = currentMonth + 1; i <= 12; i++) {
        monthList.push(i);
      }
    }

    return monthList;
  }
  static getMMDD(date: Date) {
    let MMDD: string;
    let DD = ('0' + date.getDate()).slice(-2);
    let MM = ('0' + (date.getMonth() + 1)).slice(-2);
    MMDD = MM + DD;
    return MMDD;
  }
  static initiateDatePicker(d: Date) {
    return {
      date: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
      jsdate: d,
    };
  }
}
