import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { CompanySettingDTO, CustomizedBrandingInfo } from 'src/app/core/models/company';
import { CompanyService } from 'src/app/core/services/company.sevice';

@Component({
  selector: 'eb-help-modal.component',
  templateUrl: './help-modal.component.html',
})
export class HelpModalComponent implements OnInit, AfterViewInit {
  brandingInfo: CustomizedBrandingInfo = {};
  companySubscription: Subscription;
  company: CompanySettingDTO = {};

  constructor(
    public modalRef: MdbModalRef<HelpModalComponent>,
    private companyService: CompanyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.companySubscription = this.companyService.companySettings$?.subscribe(res => {
      this.company = res;
      setTimeout(() => {
        this.loadBrandingInfo();
      }, 10);
    });
  }

  ngAfterViewInit(): void {}

  loadBrandingInfo(): void {
    this.companySubscription = this.companyService.getBrandingInfo(this.company.idCompany).subscribe(resp => {
      if (resp) {
        this.brandingInfo = resp;
      }
    });
  }

  redirectToFaqs() {
    this.modalRef.close();
    this.router.navigateByUrl('/guides-n-faq');
  }
}
