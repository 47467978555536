import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { filter } from 'rxjs';
import { AppConfigService } from './core/services/shared-services/app-config.service';
import { AuthService } from './core/services/shared-services/auth.service';
import { NotificationService } from './core/services/shared-services/notification.service';
import { ButtonText, Messages, ModalCssClass, PageTitle } from './shared/app.constants';
import { MessageComponent } from './shared/modal/message/message.component';
import { AppDispatcher } from './store/app.dispatcher';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'eb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  countdown?: number = null;
  lastPing?: Date = null;
  title = 'employeebenefitapp';
  timedOut = false;
  timeLapse: number;
  modalRef: any | MdbModalRef<MessageComponent>;

  constructor(
    private router: Router,
    private appDispatcher: AppDispatcher,
    private idle: Idle,
    private keepalive: Keepalive,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private notificationService: NotificationService,
    private titleService: Title
  ) {
    this.authService.isAuthenticated$.subscribe(isAuthenticated => {
      if (!isAuthenticated) {
        this.cleanUp();
      }
    });
    this.setupTimeoutWatch();

    // // Detect page refresh event and reinitialized the store
    // this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe(event => {
    //   if (event.id === 1 && event.url === event.urlAfterRedirects) {
    //     // console.log('page refreshed');
    //     // this.appDispatcher.initializeStore();
    //   }
    // });
    this.titleService.setTitle(PageTitle.AppName);
  }
  ngOnInit(): void {
    this.reset();
  }
  reset() {
    this.idle.watch();
    this.countdown = null;
    this.lastPing = null;
  }
  openTimeoutPopup = () => {
    this.modalRef = this.notificationService.openPopupMessage(
      Messages.idleTitle,
      Messages.idleMessage,
      true,
      ButtonText.BackToLogin,
      ModalCssClass.MediumModal
    );
    this.modalRef.onClose.subscribe(() => {
      this.router.navigateByUrl('/');
    });
  };
  private setupTimeoutWatch() {
    // sets an idle timeout of 15 minutes
    this.idle.setIdle(AppConfigService.settings.idleConfig.idleTimeout); //in second
    // after next 5 minutes of inactivity, the user will be considered timed out.
    this.idle.setTimeout(AppConfigService.settings.idleConfig.idleSurplusTimeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.openTimeoutPopup();
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.countdown = null;
      this.cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.modalRef.close();
      this.timeout();
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => (this.countdown = seconds));

    // set keepalive parameters, omit if not using keepalive
    this.keepalive.interval(AppConfigService.settings.idleConfig.keepalive); // will ping at this interval while not idle, in seconds
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date())); // do something when it pings
    this.reset();
  }
  private cleanUp() {
    this.idle.stop();
    this.keepalive.stop();
  }
  private timeout() {
    this.authService.logout();
  }
}
