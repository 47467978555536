import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedComponent } from './authentication/access-denied/access-denied.component';
import { HomeComponent } from './authentication/home/home.component';
import { LoginComponent } from './authentication/login/login.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { PasswordResetComponent } from './authentication/password-reset/password-reset.component';
import { EmployeeVerificationComponent } from './authentication/user-registration/employee-verification.component';
import { NYSEmployeeVerificationComponent } from './authentication/user-registration/nys-employee-verification.component';
import { NYSTellUsAboutYouComponent } from './authentication/user-registration/nys-tell-us-about-you.component';
import { IntroduceUserComponent } from './authentication/user-registration/introduce-user.component';
import { MyProfileComponent } from './authentication/user-registration/my-profile.component';
import { ProfileSetupComponent } from './authentication/user-registration/profile-setup.component';
import { ResendEmailComponent } from './authentication/user-registration/resend-email.component';
import { SetUpYourAccountComponent } from './authentication/user-registration/set-up-your-account.component';
import { TellUsAboutYouComponent } from './authentication/user-registration/tell-us-about-you.component';
import { VerifyAccountComponent } from './authentication/user-registration/verify-account.component';
import { UserNameRecoverComponent } from './authentication/username-recover/username-recover.component';
import { AuthGuard } from './core/services/shared-services/auth-guard.service';
import { FaqComponent } from './faq/faq.component';
import { SharedModule } from './shared/shared.module';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'claim',
    loadChildren: () => import('./claim/claim.module').then(m => m.ClaimModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'card',
    loadChildren: () => import('./card/card.module').then(m => m.CardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'elect-benefits',
    loadChildren: () => import('./elect-benefits/elect-benefits.module').then(m => m.ElectBenefitsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'marketplace',
    loadChildren: () => import('./marketplace/marketplace.module').then(m => m.MarketPlaceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'parking',
    loadChildren: () => import('./parking/parking.module').then(m => m.ParkingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
    canActivate: [AuthGuard],
  },
  { path: 'username-recover', component: UserNameRecoverComponent },
  { path: 'employee-verification', component: EmployeeVerificationComponent },
  { path: 'nys-employee-verification', component: NYSEmployeeVerificationComponent},
  { path: 'tell-us-about-you', component: TellUsAboutYouComponent },
  { path: 'nys-tell-us-about-you', component: NYSTellUsAboutYouComponent},
  { path: 'set-up-your-account', component: SetUpYourAccountComponent },
  { path: 'verify-account', component: VerifyAccountComponent },
  { path: 'resend-email', component: ResendEmailComponent },
  { path: 'introduce-user', component: IntroduceUserComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'profile-setup', component: ProfileSetupComponent },
  { path: 'error', component: HomeComponent },
  { path: 'state', component: HomeComponent },
  { path: 'code', component: HomeComponent },
  { path: 'guides-n-faq', component: FaqComponent },
  {
    path: 'member-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./member-profile/member-profile.module').then(m => m.MemberProfileModule),
  },
  { path: '**', component: HomeComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
