<div class="eb-cards row mx-0 pl-0 pr-0">
  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 pl-0 pr-0">
    <div class="border-0">
      <div class="mx-0 p-2 pb-lg-3 row help-menu-header bg-header-color">
        <div class="col-9 col-xl-9">
          <h5 class="modal-title pt-3" id="messageModalLabel">
            <i class="fc fc-person-circle font-40 text-red"></i><span class="font-weight-600"> Profile Menu</span>
          </h5>
        </div>
        <div class="col-3 col-xl-3 pr-0 pr-lg-0 text-right">
          <button type="button" class="bg-transparent border-0" aria-label="Close" (click)="closeEditProfile()">
            <img src="assets/images/close.png" class="close-focus" alt="icon" />
          </button>
        </div>
      </div>
      <div class="mt-2 pb-0">
        <ul class="pl-0 menu-list">
          <li *ngIf="isAuthenticated" class="menu-items px-3 mx-0 mt-lg-4 mt-2">
            <button type="button" class="bg-transparent border-0" (click)="openEditMemberProfile()">
              <img src="assets/images/profile_card.png" alt="icon" /><span class="ml-lg-3 ml-2">Edit Profile</span>
            </button>
          </li>
          <li *ngIf="isAuthenticated" class="menu-items px-3 mx-0 mt-lg-3 mt-2">
            <button type="button" class="bg-transparent border-0" aria-label="Close" (click)="logout()">
              <img src="assets/images/meeting_room.png" alt="icon" /><span class="ml-lg-4 ml-2">Log Out</span>
            </button>
          </li>
          <li *ngIf="!isAuthenticated" class="menu-items px-4 mx-0 mt-lg-3">
            <button type="button" class="bg-transparent border-0" aria-label="Close" (click)="login()">
              <img src="assets/images/logout.png" alt="icon" /><span class="ml-lg-3">Login</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
