import { AbstractControl } from '@angular/forms';
import { Route } from '@angular/router';
import * as moment from 'moment';
import { ActionTypeCode, ParkingProductCode, ParkingProducts, ShoppingCartMode, Status } from '../shared/app.constants';
import { ParkingTypes, ProductTypeAdminCode } from '../shared/app.enums';
import { DateUtils } from './date-utils';
import { CartProductDTOExtended, StatusIcons } from './models/app';
import { DebitCardDTO } from './models/card';
import { ContentManagementDTO } from './models/content-management';
import { ProductSimpleInfoWithTermAndCondition } from './models/parking';

export class AppUtils {
  public static firstLogin = false;
  private static orderTypes: Array<any> = [
    { id: 1, name: 'Transit' },
    { id: 2, name: 'Parking' },
    { id: 3, name: 'Bicycle' },
    { id: 4, name: 'Transit Reimbursements' },
    { id: 5, name: 'Parking Reimbursements' },
    { id: 6, name: 'Transit Saved Value' },
    { id: 7, name: 'Parking Saved Value' },
    { id: 8, name: 'Employer Parking' },
    { id: 10, name: 'Mobility Benefit' },
    { id: 11, name: 'Bike Mobility' },
    { id: 12, name: 'TicketRestaurant' },
    { id: 13, name: 'Ride Mobility' },
    { id: 14, name: 'BIKESHAREMBB' },
    { id: 15, name: 'Parking Cashout' },
  ];
  constructor(private router: Route) {}
  public static getStatusIcon(status?: string): StatusIcons {
    let cssClasses: StatusIcons = {};
    switch (status?.toLowerCase()) {
      case Status.Pending:
        cssClasses.icon = 'fc fc-clock';
        cssClasses.parent = 'pending-status';
        break;
      case Status.Approved:
        cssClasses.icon = 'fc fc-check-circle-fill';
        cssClasses.parent = 'approved-status';
        break;
      case Status.PartiallyApproved:
        cssClasses.icon = 'far fa-check-circle';
        cssClasses.parent = 'approved-status';
        break;
      case Status.Declined:
        cssClasses.icon = 'fc fc-x-circle-fill';
        cssClasses.parent = 'declined-status';
        break;
      case Status.Denied:
        cssClasses.icon = 'fc fc-x-circle-fill';
        cssClasses.parent = 'declined-status';
        break;
      case Status.DeniedByCompany:
        cssClasses.icon = 'fc fc-x-circle-fill';
        cssClasses.parent = 'declined-status';
        break;
      case Status.Received:
        cssClasses.icon = 'fc fc-clock';
        cssClasses.parent = 'received-status';
        break;
      case Status.Fulfilled:
        cssClasses.parent = 'approved-status';
        break;
      case Status.sentFullfillment:
        cssClasses.parent = 'received-status';
        break;
      case Status.Purchase:
        cssClasses.parent = 'approved-status';
        break;
      case Status.purchase:
        cssClasses.parent = 'approved-status';
        break;
    }
    return cssClasses;
  }
  public static getStatusIconGrid(status?: string): StatusIcons {
    let cssClasses: StatusIcons = {};
    switch (status?.toLowerCase()) {
      case Status.Pending:
        cssClasses.icon = 'fc fc-clock';
        cssClasses.parent = 'eb-grid-pending-status';
        break;
      case Status.PendingApproval:
        cssClasses.icon = 'fc fc-clock';
        cssClasses.parent = 'eb-grid-pending-status';
        break;
      case Status.InReview:
        cssClasses.icon = 'fc fc-clock';
        cssClasses.parent = 'eb-grid-pending-status';
        break;
      case Status.Approved:
        cssClasses.parent = 'eb-grid-approved-status';
        break;
      case Status.ApprovedCompany:
        cssClasses.parent = 'eb-grid-approved-status';
        break;
      case Status.PartiallyApproved:
        cssClasses.icon = 'far fa-check-circle';
        cssClasses.parent = 'eb-grid-partial-approved-status';
        break;
      case Status.Declined:
        cssClasses.icon = 'fc fc-x-circle-fill';
        cssClasses.parent = 'eb-grid-declined-status';
        break;
      case Status.Denied:
        cssClasses.icon = 'fc fc-x-circle-fill';
        cssClasses.parent = 'eb-grid-declined-status';
        break;
      case Status.DeniedByCompany:
        cssClasses.icon = 'fc fc-x-circle-fill';
        cssClasses.parent = 'eb-grid-declined-status';
        break;
      case Status.Received:
        cssClasses.icon = 'fc fc-clock';
        cssClasses.parent = 'eb-grid-pending-status';
        break;
      case Status.New:
        cssClasses.icon = 'fc fc-clock';
        cssClasses.parent = 'received-status';
        break;
      case Status.Fulfilled:
        cssClasses.parent = 'approved-status';
        break;
      case Status.sentFullfillment:
        cssClasses.parent = 'received-status';
        break;
      case Status.Purchase:
        cssClasses.parent = 'approved-status';
        break;
      case Status.purchase:
        cssClasses.parent = 'approved-status';
        break;
    }
    return cssClasses;
  }
  public static get memberId(): string {
    const token = localStorage.getItem('id_token_claims_obj');
    let member_id: any;
    if (token) {
      member_id = JSON.parse(token).member_id;
    }
    return member_id;
  }

  public static get appRoles(): string[] {
    const token = localStorage.getItem('id_token_claims_obj');
    let appRoles = [];
    if (token) {
      appRoles = JSON.parse(token).role;
    }
    return appRoles ? appRoles : [];
  }
  public static isInRoles(rolenames: string): boolean {
    const inputRoles = rolenames.split('|');
    return inputRoles.some(role => {
      if (AppUtils.appRoles === undefined) {
        return false;
      } else {
        return AppUtils.appRoles.filter(claimRole => claimRole === role).length > 0;
      }
    });
  }
  public static isUserImpersonated(): boolean {
    if (AppUtils.appRoles === undefined) {
      return false;
    } else {
      return AppUtils.appRoles.filter(claimRole => claimRole === 'ImpersonatedUser').length > 0;
    }
  }
  public static isEmployerOrderingRole(): boolean {
    if (AppUtils.appRoles === undefined) {
      return false;
    } else {
      return AppUtils.appRoles.filter(claimRole => claimRole === 'EmployerOrderingRole').length > 0;
    }
  }
  public static isUserAllowedToOrder(): boolean {
    if (AppUtils.isEmployerOrderingRole()) {
      if (AppUtils.isUserImpersonated()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  public static getDashboardUrl(): string {
    const appRoles: string[] = AppUtils.appRoles;
    if (appRoles.length > 0) {
      if (appRoles.indexOf('LsaRole') > -1) {
        return '/dashboard/lsa';
      } else if (appRoles.indexOf('TRRole') > -1) {
        return '/dashboard/tr';
      } else {
        return '/dashboard/home';
      }
    } else {
      return '/dashboard/home';
    }
  }
  public static get firstTimeLogin(): boolean {
    const token = localStorage.getItem('id_token_claims_obj');
    let firstTimeLogin;
    if (token) {
      firstTimeLogin = JSON.parse(token).member_id;
    }
    return AppUtils.firstLogin;
  }

  public static getTRNproductRoute(product: CartProductDTOExtended) {
    let productRoute: string;
    if (
      product.idProductType === ProductTypeAdminCode.DF ||
      product.idProductType === ProductTypeAdminCode.CHARLIE ||
      product.idProductType === ProductTypeAdminCode.STV ||
      product.idProductType === ProductTypeAdminCode.SNUM ||
      product.idProductType === ProductTypeAdminCode.RPCI ||
      product.idProductType === ProductTypeAdminCode.CP ||
      product.idProductType === ProductTypeAdminCode.SCC ||
      product.idProductType === ProductTypeAdminCode.AMC ||
      product.idProductType === ProductTypeAdminCode.GSER
    ) {
      productRoute = '/elect-benefits/trn-products/default';
    } else if (
      product.idProductType === ProductTypeAdminCode.VDP ||
      product.idProductType === ProductTypeAdminCode.SMLD
    ) {
      productRoute = '/elect-benefits/trn-products/vanpool';
    } else if (
      product.idProductType === ProductTypeAdminCode.CCCPM ||
      product.idProductType === ProductTypeAdminCode.PPC
    ) {
      productRoute = '/elect-benefits/trn-products/pccc';
    } else if (
      product.idProductType === ProductTypeAdminCode.SC ||
      product.idProductType === ProductTypeAdminCode.MLMR ||
      product.idProductType === ProductTypeAdminCode.LIRR ||
      product.idProductType === ProductTypeAdminCode.TLINK ||
      product.idProductType === ProductTypeAdminCode.TLINKS ||
      product.idProductType === ProductTypeAdminCode.FCARD
    ) {
      productRoute = '/elect-benefits/trn-products/smartcard';
    } else if (product.idProductType === ProductTypeAdminCode.V) {
      productRoute = '/elect-benefits/trn-products/voucher';
    } else if (product.idProductType === ProductTypeAdminCode.GROUP) {
      productRoute = '/elect-benefits/trn-products/group';
    } else if (
      product.idProductType === ProductTypeAdminCode.S2S ||
      product.idProductType === ProductTypeAdminCode.ZONE2ZONE
    ) {
      productRoute = '/elect-benefits/trn-products/station';
    } else if (
      product.idProductType === ProductTypeAdminCode.VCTC ||
      product.idProductType === ProductTypeAdminCode.VCTCS ||
      product.idProductType === ProductTypeAdminCode.VGROUP ||
      product.idProductType === ProductTypeAdminCode.SMLD
    ) {
      productRoute = '/elect-benefits/trn-products/ventra';
    }

    return productRoute;
  }

  public static getTRNproductRouteByProductType(idProductType: number, productCode: string) {
    let productRoute: string;
    if (
      idProductType === ProductTypeAdminCode.DF ||
      idProductType === ProductTypeAdminCode.CHARLIE ||
      idProductType === ProductTypeAdminCode.STV ||
      idProductType === ProductTypeAdminCode.SNUM ||
      idProductType === ProductTypeAdminCode.RPCI ||
      idProductType === ProductTypeAdminCode.CP ||
      idProductType === ProductTypeAdminCode.SCC ||
      idProductType === ProductTypeAdminCode.AMC ||
      idProductType === ProductTypeAdminCode.GSER
    ) {
      productRoute = '/elect-benefits/trn-products/default';
    } else if (idProductType === ProductTypeAdminCode.VDP || idProductType === ProductTypeAdminCode.SMLD) {
      productRoute = '/elect-benefits/trn-products/vanpool';
    } else if (idProductType === ProductTypeAdminCode.CCCPM || idProductType === ProductTypeAdminCode.PPC) {
      productRoute = '/elect-benefits/trn-products/pccc';
    } else if (
      idProductType === ProductTypeAdminCode.SC ||
      idProductType === ProductTypeAdminCode.MLMR ||
      idProductType === ProductTypeAdminCode.LIRR ||
      idProductType === ProductTypeAdminCode.TLINK ||
      idProductType === ProductTypeAdminCode.TLINKS ||
      idProductType === ProductTypeAdminCode.FCARD
    ) {
      productRoute = '/elect-benefits/trn-products/smartcard';
    } else if (idProductType === ProductTypeAdminCode.V) {
      productRoute = '/elect-benefits/trn-products/voucher';
    } else if (idProductType === ProductTypeAdminCode.GROUP) {
      productRoute = '/elect-benefits/trn-products/group';
    } else if (idProductType === ProductTypeAdminCode.S2S || idProductType === ProductTypeAdminCode.ZONE2ZONE) {
      productRoute = '/elect-benefits/trn-products/station';
    } else if (
      idProductType === ProductTypeAdminCode.VCTC ||
      idProductType === ProductTypeAdminCode.VCTCS ||
      idProductType === ProductTypeAdminCode.VGROUP
    ) {
      productRoute = '/elect-benefits/trn-products/ventra';
    }

    return productRoute;
  }

  public static getPRKproductRoute(prod: ProductSimpleInfoWithTermAndCondition, mode?: string) {
    let productRoute: string;

    if (prod.productTypeCode === ParkingProductCode.DirectPayCode) {
      if (mode === ShoppingCartMode.Create) {
        productRoute = '/elect-benefits/prk/find-provider';
      } else if (mode === ShoppingCartMode.Edit) {
        productRoute = '/elect-benefits/prk/direct-pay';
      }
      // productRoute = '/elect-benefits/prk/direct-pay';
    } else if (
      prod.productTypeCode === ParkingProductCode.PCCCCode ||
      prod.productTypeCode === ParkingProductCode.AutoSweepCode ||
      prod.productTypeCode === ParkingProductCode.PCCCFlexCode ||
      prod.productTypeCode === ParkingProductCode.NYCPCCCCode ||
      prod.productTypeCode === ParkingProductCode.CUNYPCCC ||
      prod.productTypeCode === ParkingProductCode.CBPCCC ||
      prod.productTypeCode === ParkingProductCode.CBPCCCAS ||
      prod.productTypeCode === ParkingProductCode.EBPCCC
    ) {
      productRoute = '/elect-benefits/prk/pccc';
    } else if (prod.productTypeCode === ParkingProductCode.VoucherCode) {
      productRoute = '/elect-benefits/prk/voucher';
    } else if (prod.productTypeCode === ParkingProductCode.ReimbursementCode) {
      productRoute = '/elect-benefits/prk/cash-reimbursement';
    } else if (prod.productTypeCode === ParkingProductCode.EmployerParking) {
      productRoute = '/elect-benefits/prk/employer-parking';
    }
    if (prod.isWMATA) {
      productRoute = '/elect-benefits/prk/smartcard';
    }
    if (prod.isCaltrain) {
      productRoute = '/elect-benefits/prk/direct-pay';
    }
    if (prod.productTypeCode === ParkingProductCode.PSC) {
      productRoute = '/elect-benefits/prk/psc';
    }
    return productRoute;
  }

  public static getPRKproductRouteByIdProductType(prod: ProductSimpleInfoWithTermAndCondition, mode?: string) {
    let productRoute: string;
    if (prod.idProductType === 24) {
      productRoute = '/elect-benefits/prk/pccc';
    } else if (prod.idProductType === 3) {
      if (prod.productName === ParkingProducts.MonthlyDirectPay || prod.productName === ParkingProducts.DirectPay) {
        if (mode === ShoppingCartMode.Create) {
          productRoute = '/elect-benefits/prk/find-provider';
        } else if (mode === ShoppingCartMode.Edit) {
          productRoute = '/elect-benefits/prk/direct-pay';
        }
      } else if (prod.productName === ParkingProducts.ParkingCashout) {
        productRoute = '/elect-benefits/prk/cash-out';
      } else if (prod.productName === ParkingProducts.EmployerParking) {
        productRoute = '/elect-benefits/prk/employer-parking';
      } else if (prod.isWMATA || prod.productName === ParkingProducts.WMataParking) {
        productRoute = '/elect-benefits/prk/smartcard';
      } else if (prod.isCaltrain || prod.productName === ParkingProducts.CalTrain) {
        productRoute = '/elect-benefits/prk/direct-pay';
      } else {
        productRoute = '/elect-benefits/prk/voucher';
      }
    } else if (prod.idProductType === 7) {
      productRoute = '/elect-benefits/prk/cash-reimbursement';
    } else if (prod.idProductType === 9 || prod.idProductType === 21) {
      productRoute = '/elect-benefits/prk/psc';
    }

    if (prod.productTypeCode === ParkingProductCode.PSC) {
      productRoute = '/elect-benefits/prk/psc';
    }
    return productRoute;
  }

  public static getPRKproductRouteByAdmCode(parkingType: number) {
    let productRoute: string;
    // console.log("parkingtype -" + parkingType);
    if (parkingType === ParkingTypes.DirectPay) {
      // if (mode === 'add') {
      //   productRoute = '/place-order/prk-find-provider';
      // } else if (mode === 'edit') {
      //   productRoute = '/place-order/prk-order-form/dp';
      // }
      productRoute = '/elect-benefits/prk/direct-pay';
    } else if (parkingType === ParkingTypes.PCCC) {
      productRoute = '/elect-benefits/prk/pccc';
    } else if (parkingType === ParkingTypes.SelfElect) {
      productRoute = '/elect-benefits/prk/voucher';
    } else if (parkingType === ParkingTypes.CashReimbursement) {
      productRoute = '/elect-benefits/prk/cash-reimbursement';
    } else if (parkingType === ParkingTypes.EmployerParking) {
      productRoute = '/elect-benefits/prk/employer-parking';
    } else if (parkingType === ParkingTypes.StackedCard) {
      productRoute = '/elect-benefits/prk/psc';
    }
    return productRoute;
  }
  public static getMBBProductRouteByIdOrderType(prod: ProductSimpleInfoWithTermAndCondition) {
    let productRoute: string;
    if (prod.idOrderType === 11 || prod.idOrderType === 13) {
      productRoute = '/elect-benefits/mbb/order';
    }
    if (prod.idOrderType === 10) {
      productRoute = '/elect-benefits/mbb/product-info';
    }
    return productRoute;
  }

  public static getBikingproductRouteByIdProductType(prod: ProductSimpleInfoWithTermAndCondition, mode?: string) {
    let productRoute: string;
    if (prod.idProductType === 3) {
      productRoute = '/elect-benefits/biking/voucher';
    }
    if (prod.idProductType === 11) {
      productRoute = '/elect-benefits/mbb/order';
    }
    return productRoute;
  }

  public static getMBBProductRoute(productCode: string) {
    let productRoute: string;
    if (productCode.indexOf('SCOOTERMBB') > -1 || productCode.indexOf('BIKSHAREMBB') > -1) {
      productRoute = '/elect-benefits/mbb/product-info';
    } else {
      productRoute = '/elect-benefits/mbb/order';
    }
    return productRoute;
  }

  public static getBikingProductRoute(productCode: string) {
    let productRoute: string;
    productRoute = '/elect-benefits/biking/voucher';
    return productRoute;
  }

  public static getBikingProductRouteByCompanySettings(productCode: string, bikmbbIsAllow: boolean) {
    let productRoute: string;

    if (bikmbbIsAllow) {
      productRoute = '/elect-benefits/mbb/order';
    } else {
      productRoute = '/elect-benefits/biking/voucher';
    }
    return productRoute;
  }
  public static isDefaultProduct(admCode: string) {
    let result: boolean;
    if (
      admCode === 'DF' ||
      admCode === 'CHARLIE' ||
      admCode === 'STV' ||
      admCode === 'SNUM' ||
      admCode === 'RPCI' ||
      admCode === 'CP' ||
      admCode === 'SCC' ||
      admCode === 'AMC'
    ) {
      result = true;
    }
    return result;
  }

  public static getActiveCardId(debitCards: DebitCardDTO[]): number {
    if (debitCards && debitCards.length > 0) {
      let cards = debitCards.filter(a => a.statusDescription === Status.Active);
      if (cards?.length > 0) {
        return cards[0].idCard;
      } else {
        return 0;
      }
    }
    return 0;
  }

  public static getOrderTypeById(idOrderType: number): string {
    return AppUtils.orderTypes.filter(orderType => orderType.id === idOrderType)[0]?.name;
  }

  public static removeHTMLTags(htmlStr: string): string {
    const pattern = new RegExp(/(<([^>]+)>)/gi);
    return new String(htmlStr).replace(/(<([^>]+)>)/gi, '');
  }
  public static formatZip(zip: string) {
    if (zip.length > 5) {
      return zip.substring(0, 5);
    } else {
      return zip;
    }
  }

  public static getProductActionTypeRoute(actiontypecode: string) {
    let routestring: string;
    switch (actiontypecode) {
      case ActionTypeCode.SCREPLACE: {
        routestring = '/elect-benefits/smartcard-replacement-form';
        break;
      }
      case ActionTypeCode.PASSNEVERRECEIVED: {
        routestring = '/elect-benefits/crm-lostpass';
        break;
      }
      case ActionTypeCode.CLAIMSTATUS: {
        routestring = '';
        break;
      }
      case ActionTypeCode.FILECLAIM: {
        routestring = '/claim/submit-claim/MS0w';
        break;
      }
      case ActionTypeCode.NEEDHELP: {
        routestring = '/elect-benefits/crm-reimbursement-form';
        break;
      }
      case ActionTypeCode.PCCCDetail: {
        routestring = '/card/home';
        break;
      }
      case ActionTypeCode.PCCCLOSTSTOLEN: {
        routestring = '/card/home';
        break;
      }
      case ActionTypeCode.PCCCNEVERRECEIVED: {
        routestring = '/card/home';
        break;
      }
      case ActionTypeCode.PCCCACTIVATE: {
        routestring = '/card/home';
        break;
      }
      case ActionTypeCode.UPLOADRECEIPT: {
        routestring = '/elect-benefits/prk/prk-affiliate-provider/new/add';
        break;
      }
      default: {
        routestring = '';
        break;
      }
    }
    return routestring;
  }
  public static getMBBProductTransportationType(productCode: string) {
    let transportationType: string;
    switch (productCode.toUpperCase()) {
      case 'BIKMBB':
        transportationType = 'BikeMBB';
        break;
      case 'SCOOTERMBB':
        transportationType = 'ScooterRental';
        break;
      case 'BIKSHAREMBB':
        transportationType = 'BikeShare';
        break;
      case 'LYFTUBRMBB':
        transportationType = 'LyftRides';
        break;
      default:
        '';
        break;
    }
    return transportationType;
  }

  public static getMBBProductTransportationTypeDisplayName(productCode: string) {
    let transportationTypeDisplayName: string;
    switch (productCode.toUpperCase()) {
      case 'SCOOTERMBB':
        transportationTypeDisplayName = 'e-scooter rentals';
        break;
      case 'BIKSHAREMBB':
        transportationTypeDisplayName = 'bike share';
        break;
      default:
        '';
        break;
    }
    return transportationTypeDisplayName;
  }

  public static isValidCCNumber(ccNumber: string) {
    // Only Visa and Mastercard accepted

    const visaPattern = new RegExp('^(4)');
    const mastercardPatern = new RegExp('^(51|52|53|54|55)');

    if (!visaPattern.test(ccNumber) && !mastercardPatern.test(ccNumber)) {
      return false;
    }

    // Logic from 3.0
    const checkNumbers: number[] = [];
    for (let index = ccNumber.length - 2; index >= 0; index -= 2) {
      checkNumbers.push(parseInt(ccNumber.slice(index, index + 1), 10) * 2);
    }

    let checkSum = 0;
    for (let index = 0; index < checkNumbers.length; index++) {
      let count = 0;
      if (checkNumbers[index] > 9) {
        for (let x = 0; x < checkNumbers[index].toString().length; x++) {
          count += parseInt(checkNumbers[index].toString().slice(x, x + 1), 10);
        }
      } else {
        count += checkNumbers[index];
      }
      checkSum += count;
    }

    let originalSum = 0;
    for (let index = ccNumber.length - 1; index >= 0; index -= 2) {
      originalSum += parseInt(ccNumber.slice(index, index + 1), 10);
    }

    return (originalSum + checkSum) % 10 === 0;
  }

  public static isProductLocked(
    productAdmCode: string,
    lastChanceToOrder: number,
    monthDelay: number,
    lirrMnrCycleDay: number
  ) {
    let isLocked = false;
    if (productAdmCode === 'LIRR' || productAdmCode === 'MLMR') {
      const cycledate = moment().date(lirrMnrCycleDay).hour(0).minute(0).second(0).millisecond(0);
      const lctodate = moment(DateUtils.FromUnix(Number(lastChanceToOrder)));
      console.log('today=', moment());
      console.log('cycledate=', cycledate);
      console.log('lctodate=', lctodate);
      console.log('lctodate month=', lctodate.month());
      console.log('today month=', moment().month());
      if (monthDelay === 1 && moment() >= cycledate && moment() <= lctodate && moment().month() === lctodate.month()) {
        isLocked = true;
      }
    }
    return isLocked;
  }
  // public static isShoppingCartLocked(currentProduct: ProductDetailInfo, member: MemberDTO) {
  //   let isLocked = false;
  //   if (member.tpaCode === 'NYC' || member.tpaCode === 'CNY') {
  //     if (
  //       member.pmsDeductionCode === '8990' ||
  //       member.pmsDeductionCode === '8997' ||
  //       member.pmsDeductionCode === '8998'
  //     ) {
  //       //Lock shopping cart only for transit. Do not lock for Parking
  //       if (currentProduct.idTransitAuthority > 0) {
  //         isLocked = true;
  //       }
  //     }
  //   }
  //   return isLocked;
  // }

  public static getPageContent(
    contentList: ContentManagementDTO[],
    contentSubPagesCode: string,
    contentCode?: string
  ): string {
    return contentList?.filter(
      item => item.contentSubPagesCode === contentSubPagesCode && item.contentCode === contentCode
    )[0]?.displayContent;
  }

  public static goToTop(id: string) {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  public static removeWhiteSpace(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
      return { required: true };
    }
    return null;
  }
  public static isValidRoutingNumber(routingN: string) {
    let sum = 0;

    for (let i = 0; i < 9; i += 3) {
      sum +=
        parseInt(routingN.slice(i, i + 1), 10) * 3 +
        parseInt(routingN.slice(i + 1, i + 2), 10) * 7 +
        parseInt(routingN.slice(i + 2, i + 3), 10);
    }

    return sum % 10 === 0;
  }
}
